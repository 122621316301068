body {
  font-family: FCIconic;
  src: local("MyFont"), url(./font/FCIconic-Regular.ttf) format("truetype");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px !important;
  height: 100%;
  background-color: #efeff5 !important;
  /* font-size: 14px; */
}

input,
select,
textarea {
  font-size: 0.85rem !important;
}

.btn,
a,
body,
span,
label,
div {
  font-size: 0.85rem;
}

.btn,
input,
select,
a,
#swal2-content,
button {
  /* font-size: 14px !important; */
}

html {
  height: 100%;
  margin: 0;
}

#root {
  height: 100%;
}

.card {
  border-radius: 16px;
  border: 0;
}

.logo {
  width: 50%;
  object-fit: contain;
}

.logo-menu {
  width: 148px;
}

.pro-sidebar-layout {
  background-color: #ffffff !important;
  position: fixed !important;
}

.pro-icon {
  background-color: #0000 !important;
  color: #333333 !important;
}

.button {
  color: #333333 !important;
}

.pro-inner-item {
  height: 56px !important;
}

.pro-inner-item:hover {
  background-color: #f5f5f5 !important;
  color: #232f85 !important;
}

.pro-item-content {
  color: #333333 !important;
}

.pro-arrow {
  color: #333333 !important;
}

.icon-arrow {
  background-color: #ccd2e3 !important;
}

.pro-inner-list-item {
  background-color: #ffffff !important;
  color: #333333 !important;
}

.social {
  width: 45px;
  height: 45px;
}

.bottom-0 {
  position: absolute;
  bottom: 0px;
}

.icon {
  font-family: FontAwesome;
  src: local("MyFont"), url(./font/fa-light-300.woff) format("woff");
  font-size: 16px;
  padding-top: 2px;
  height: 20px;
}

.icon-calender {
  font-family: FontAwesome;
  src: local("MyFont"), url(./font/fa-light-300.woff) format("woff");
  font-size: 16px;

}

.icon-brand {
  font-family: FontAwesome;
  src: local("MyFont"), url(./font/fa-brands-400.woff) format("woff");
  font-size: 20px;
  height: 24px;
}

.icon-primary {
  font-family: FontAwesome;
  src: local("MyFont"), url(./font/fa-brands-400.woff) format("woff");
  font-size: 20px;
  height: 24px;
  color: #50a759;
  margin: 5px;
}

.icon-big {
  font-family: FontAwesome;
  src: local("MyFont"), url(./font/fa-brands-400.woff) format("woff");
  font-size: 32px;
  height: 36px;
  color: #888888;
  margin: 5px;
}

.icon-right {
  position: absolute !important;
  padding: 12px;
  margin-left: -40px;
}

input[type="date"] {
  position: relative;
  padding: 10px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

input[type="date"]:before {
  color: transparent;
  background: none;
  display: block;
  font-family: FontAwesome;
  src: local("MyFont"), url(./font/fa-light-300.woff) format("woff");
  content: "\f073";
  /* This is the calendar icon in FontAwesome */
  width: 15px;
  height: 20px;
  position: absolute;
  right: 15px;
  top: 6px;
  color: #999;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* .switch .slider-text-left {
  position: absolute;
  padding: 5px;
  color: #fff;
  font-size: 14px;
}
.switch .slider-text-right {
  position: absolute;
  right: 0px;
  padding: 5px;
  color: #fff;
  font-size: 14px;
} */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f64e60;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  text-align: right;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #50a759;
}

input:focus+.slider {
  box-shadow: 0 0 1px #50a759;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input[type="checkbox"],
input[type="radio"] {
  filter: hue-rotate(250deg);
  width: 18px;
  height: 18px;
  border-radius: 4px;
}

.tooltip {
  position: relative;
  display: block;
}

.center {
  align-items: center;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #ffffff;
  color: #000000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.contain-tag {
  display: inline-block;
}

.tag {
  height: 34px;
  border-radius: 17px;
  display: flex;
  flex-direction: row;
}

.tag .text-tag {
  padding-top: 2px;
  max-width: 300px;
  height: 34px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px 5px 0px 10px;
  background-color: #ebebeb;
  border-top-left-radius: 17px;
  border-bottom-left-radius: 17px;
}

.tag .text-tag-round {
  padding-top: 2px;
  max-width: 300px;
  height: 34px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 5px 10px 0px 10px;
  background-color: #ebebeb;
  border-radius: 17px;
}

.btn {
  border-radius: 10px;
}

.btn-tag {
  border: 0px;
  height: 34px;
  width: 34px;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
  background-color: #ebebeb;
  padding-top: 6px;
}

.status-success {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #1bc5bd;
  margin: 5px;
}

.status-warning {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffa800;
  margin: 5px;
}

.status-fail {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #f64e60;
  margin: 5px;
}

.status-box-success {
  height: 24px;
  border-radius: 5px;
  background-color: #b8ffd4;
  margin: 5px;
  padding: 4px 10px 0px 10px;
  display: inline-block;
  font-size: 12px;
  color: #209850;
}

.status-box-warning {
  height: 24px;
  border-radius: 5px;
  background-color: #ffeeb9;
  margin: 5px;
  padding: 4px 10px 0px 10px;
  display: inline-block;
  font-size: 12px;
  color: #ffa800;
}

.status-box-fail {
  height: 24px;
  border-radius: 5px;
  background-color: #ffbfbf;
  margin: 5px;
  padding: 4px 10px 0px 10px;
  display: inline-block;
  font-size: 12px;
  color: #f64e60;
}

.status-text-success {
  height: 24px;
  margin: 5px;
  display: inline-block;
  font-size: 12px;
  color: #209850;
}

.status-text-warning {
  height: 24px;
  margin: 5px;
  display: inline-block;
  font-size: 12px;
  color: #ffa800;
}

.status-text-fail {
  height: 24px;
  margin: 5px;
  display: inline-block;
  font-size: 12px;
  color: #f64e60;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 8px;
}

.avatar label {
  color: #50a759;
}

.avatar-disable {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: #3699ff50;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 8px;
}

.avatar-disable label {
  color: #013395;
}

/* MENU */

.topnav {
  overflow: hidden;
  background-color: #333;
}

.topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.active {
  background-color: #f5f5f5;
  color: #232f85;
}

.topnav .icon {
  display: none;
}

.dropdown {
  float: left;
  /* overflow: hidden; */
}

.dropdown .dropbtn {
  font-size: 17px;
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.topnav a:hover,
.dropdown:hover .dropbtn {
  background-color: #555;
  color: white;
}

.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.pro-sidebar {
  height: 100% !important;
  width: 260px !important;
  min-width: 260px !important;
}

.pro-sidebar-layout {
  background-color: #ffffff;
  color: #5c6873;
  width: 260px;
}

.pro-inner-item:active {
  background-color: #5c6873;
  color: #ffffff;
}

.pro-inner-item:active span {
  color: #ffffff;
}

.pro-inner-item:active .pro-arrow {
  color: #ffffff;
}

.pro-inner-item:hover {
  background-color: #5c6873;
  color: #ffffff;
}

.pro-inner-item:hover .pro-icon-wrapper {
  background-color: #5c6873;
  color: #ffffff;
}

.pro-inner-item:hover .pro-icon-wrapper .pro-icon {
  background-color: #5c6873;
  color: #ffffff;
}


/* CSS SIDEBAR */
.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item>div>ul {
  padding: 0px !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  padding-left: 0px !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item .pro-inner-item {
  padding-left: 40px !important;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item>.pro-inner-item>.pro-icon-wrapper {
  background-color: #00000000;
}

.pro-inner-item:hover span {
  color: #ffffff;
}

.pro-inner-item:hover .pro-arrow {
  color: #ffffff;
}

.pro-inner-list-item {
  background-color: #ffffff;
  color: #5c6873;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
  position: relative;
  background-color: #ffffff;
  color: #5c6873;
}

.pro-inner-list-item:hover {
  background-color: #5c6873;
  color: #ffffff;
}

.pro-inner-list-item:active {
  background-color: #5c6873;
  color: #ffffff;
}

.pro-icon {
  background-color: #ffffff;
  color: #5c6873;
}

.pro-icon:hover {
  background-color: #5c6873;
  color: #ffffff;
}

.pro-icon:active {
  background-color: #5c6873;
  color: #ffffff;
}

.menu-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

input[type="search"] {
  padding-left: 36px;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iNyIgc3Ryb2tlPSIjOTk5OTk5IiBzdHJva2Utd2lkdGg9IjIiLz4KPHBhdGggZD0iTTExIDhDMTAuNjA2IDggMTAuMjE1OSA4LjA3NzYgOS44NTE5NSA4LjIyODM2QzkuNDg3OTcgOC4zNzkxMyA5LjE1NzI2IDguNjAwMSA4Ljg3ODY4IDguODc4NjhDOC42MDAxIDkuMTU3MjYgOC4zNzkxMyA5LjQ4Nzk3IDguMjI4MzYgOS44NTE5NUM4LjA3NzYgMTAuMjE1OSA4IDEwLjYwNiA4IDExIiBzdHJva2U9IiM5OTk5OTkiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxwYXRoIGQ9Ik0yMCAyMEwxNyAxNyIgc3Ryb2tlPSIjOTk5OTk5IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K) no-repeat 8px;
  max-width: 240px;
  width: 100%;
}

.search {
  padding-left: 36px;
  background: transparent url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGNpcmNsZSBjeD0iMTEiIGN5PSIxMSIgcj0iNyIgc3Ryb2tlPSIjOTk5OTk5IiBzdHJva2Utd2lkdGg9IjIiLz4KPHBhdGggZD0iTTExIDhDMTAuNjA2IDggMTAuMjE1OSA4LjA3NzYgOS44NTE5NSA4LjIyODM2QzkuNDg3OTcgOC4zNzkxMyA5LjE1NzI2IDguNjAwMSA4Ljg3ODY4IDguODc4NjhDOC42MDAxIDkuMTU3MjYgOC4zNzkxMyA5LjQ4Nzk3IDguMjI4MzYgOS44NTE5NUM4LjA3NzYgMTAuMjE1OSA4IDEwLjYwNiA4IDExIiBzdHJva2U9IiM5OTk5OTkiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIi8+CjxwYXRoIGQ9Ik0yMCAyMEwxNyAxNyIgc3Ryb2tlPSIjOTk5OTk5IiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIvPgo8L3N2Zz4K) no-repeat 8px;
  width: 100%;
}

.bg-gray {
  background-color: #e3e3e3 !important;
}

.bg-gray-c9 {
  background-color: #c9c9c9 !important;
}

.bg-gray-f5 {
  background-color: #f5f5f5 !important;
}

.bg-stock-red {
  background-color: #eb5757 !important;
  text-align: right;
  border-radius: 8px;
  width: 70px;
  min-width: 70px;
  padding: 0px 24px;
  font-size: 16px;
  color: floralwhite;
}

.bg-stock-grey {
  background-color: #ebebeb !important;
  text-align: right;
  border-radius: 8px;
  width: 70px;
  min-width: 70px;
  padding: 0px 24px;
  font-size: 16px;
}

.profile-image {
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: 16px;
  background-color: #dfdfdf;
}

.bodyw-100 {
  width: calc(100% - 260px) !important;
}

@media screen and (max-width: 992px) {
  .breadcrumb-component {
    left: 0;
  }

  .bodyw-100 {
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) {

  .topnav a:not(:first-child),
  .dropdown .dropbtn {
    display: none;
  }

  .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .topnav.responsive {
    position: relative;
  }

  .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }

  .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }

  .topnav.responsive .dropdown {
    float: none;
  }

  .topnav.responsive .dropdown-content {
    position: relative;
  }

  .topnav.responsive .dropdown .dropbtn {
    display: block;
    width: 100%;
    text-align: left;
  }
}

.header-menu {
  width: 100%;
  height: 55px;
  display: flex;
  flex-direction: row;
  margin: 0;
  background-color: #1b8008;
  border-bottom: 1px solid #c8ced3;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
}

.header-icon {
  height: 25px;
}

.profile-icon {
  height: 30px;
}

@font-face {
  font-family: FontAwesome;
  src: local("MyFont"), url(./font/fa-light-300.woff) format("woff");
  font-weight: 300;
}

@font-face {
  font-family: FontAwesome;
  src: local("MyFont"), url(./font/fa-brands-400.woff) format("woff");
  font-weight: 300;
}

@font-face {
  font-family: FCIconic;
  src: local("MyFont"), url(./font/FCIconic-Light.ttf) format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: FCIconic;
  src: local("MyFont"), url(./font/FCIconic-Light.ttf) format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: FCIconic;
  src: local("MyFont"), url(./font/FCIconic-Regular.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: FCIconic;
  src: local("MyFont"), url(./font/FCIconic-Bold.ttf) format("truetype");
  font-weight: bold;
}

.w3-table,
.w3-table-all {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  display: table;
}

.w3-table-all {
  border: 1px solid #ccc;
}

.w3-bordered tr,
.w3-table-all tr {
  border-bottom: 1px solid #ddd;
}

.w3-striped tbody tr:nth-child(even) {
  background-color: #f1f1f1;
}

.w3-table-all tr:nth-child(odd) {
  background-color: #fff;
}

.w3-table-all tr:nth-child(even) {
  background-color: #f1f1f1;
}

.w3-hoverable tbody tr:hover,
.w3-ul.w3-hoverable li:hover {
  background-color: #ccc;
}

.w3-centered tr th,
.w3-centered tr td {
  text-align: center;
}

.w3-table td,
.w3-table th,
.w3-table-all td,
.w3-table-all th {
  padding: 8px 8px;
  display: table-cell;
  text-align: left;
  vertical-align: top;
}

.w3-table th:first-child,
.w3-table td:first-child,
.w3-table-all th:first-child,
.w3-table-all td:first-child {
  padding-left: 16px;
}

.w3-btn,
.w3-button {
  border: none;
  display: inline-block;
  padding: 8px 16px;
  vertical-align: middle;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: inherit;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
}

.w3-btn:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.w3-btn,
.w3-button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.w3-disabled,
.w3-btn:disabled,
.w3-button:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

.w3-disabled *,
:disabled * {
  pointer-events: none;
}

.w3-btn.w3-disabled:hover,
.w3-btn:disabled:hover {
  box-shadow: none;
}

.w3-badge,
.w3-tag {
  background-color: #000;
  color: #fff;
  display: inline-block;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}

.w3-badge {
  border-radius: 50%;
}

.w3-ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.w3-ul li {
  padding: 8px 16px;
  border-bottom: 1px solid #ddd;
}

.w3-ul li:last-child {
  border-bottom: none;
}

.w3-tooltip,
.w3-display-container {
  position: relative;
}

.w3-tooltip .w3-text {
  display: none;
}

.w3-tooltip:hover .w3-text {
  display: inline-block;
}

.w3-ripple:active {
  opacity: 0.5;
}

.w3-ripple {
  transition: opacity 0s;
}

.w3-input {
  padding: 8px;
  display: block;
  border: none;
  border-bottom: 1px solid #ccc;
  width: 100%;
}

.w3-select {
  padding: 9px 0;
  width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
}

.w3-dropdown-click,
.w3-dropdown-hover {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.w3-dropdown-hover:hover .w3-dropdown-content {
  display: block;
}

.w3-dropdown-hover:first-child,
.w3-dropdown-click:hover {
  background-color: #ccc;
  color: #000;
}

.w3-dropdown-hover:hover>.w3-button:first-child,
.w3-dropdown-click:hover>.w3-button:first-child {
  background-color: #ccc;
  color: #000;
}

.w3-dropdown-content {
  cursor: auto;
  color: #000;
  background-color: #fff;
  display: none;
  position: absolute;
  min-width: 160px;
  margin: 0;
  padding: 0;
  z-index: 1;
}

.w3-check,
.w3-radio {
  width: 24px;
  height: 24px;
  position: relative;
  top: 6px;
}

.w3-sidebar {
  height: 100%;
  width: 200px;
  background-color: #fff;
  position: fixed !important;
  z-index: 1;
  overflow: auto;
}

.w3-bar-block .w3-dropdown-hover,
.w3-bar-block .w3-dropdown-click {
  width: 100%;
}

.w3-bar-block .w3-dropdown-hover .w3-dropdown-content,
.w3-bar-block .w3-dropdown-click .w3-dropdown-content {
  min-width: 100%;
}

.w3-bar-block .w3-dropdown-hover .w3-button,
.w3-bar-block .w3-dropdown-click .w3-button {
  width: 100%;
  text-align: left;
  padding: 8px 16px;
}

.w3-main,
#main {
  transition: margin-left 0.4s;
}

.w3-modal {
  z-index: 3;
  display: none;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.w3-modal-content {
  margin: auto;
  background-color: #fff;
  position: relative;
  padding: 0;
  outline: 0;
  width: 600px;
}

.w3-bar {
  width: 100%;
  overflow: hidden;
}

.w3-center .w3-bar {
  display: inline-block;
  width: auto;
}

.w3-bar .w3-bar-item {
  padding: 8px 16px;
  float: left;
  width: auto;
  border: none;
  display: block;
  outline: 0;
}

.w3-bar .w3-dropdown-hover,
.w3-bar .w3-dropdown-click {
  position: static;
  float: left;
}

.w3-bar .w3-button {
  white-space: normal;
}

.w3-bar-block .w3-bar-item {
  width: 100%;
  display: block;
  padding: 8px 16px;
  text-align: left;
  border: none;
  white-space: normal;
  float: none;
  outline: 0;
}

.w3-bar-block.w3-center .w3-bar-item {
  text-align: center;
}

.w3-block {
  display: block;
  width: 100%;
}

.w3-responsive {
  display: block;
  overflow-x: auto;
}

.w3-container:after,
.w3-container:before,
.w3-panel:after,
.w3-panel:before,
.w3-row:after,
.w3-row:before,
.w3-row-padding:after,
.w3-row-padding:before,
.w3-cell-row:before,
.w3-cell-row:after,
.w3-clear:after,
.w3-clear:before,
.w3-bar:before,
.w3-bar:after {
  content: "";
  display: table;
  clear: both;
}

.w3-col,
.w3-half,
.w3-third,
.w3-twothird,
.w3-threequarter,
.w3-quarter {
  float: left;
  width: 100%;
}

.w3-col.s1 {
  width: 8.33333%;
}

.w3-col.s2 {
  width: 16.66666%;
}

.w3-col.s3 {
  width: 24.99999%;
}

.w3-col.s4 {
  width: 33.33333%;
}

.w3-col.s5 {
  width: 41.66666%;
}

.w3-col.s6 {
  width: 49.99999%;
}

.w3-col.s7 {
  width: 58.33333%;
}

.w3-col.s8 {
  width: 66.66666%;
}

.w3-col.s9 {
  width: 74.99999%;
}

.w3-col.s10 {
  width: 83.33333%;
}

.w3-col.s11 {
  width: 91.66666%;
}

.w3-col.s12 {
  width: 99.99999%;
}

@media (min-width: 601px) {
  .w3-col.m1 {
    width: 8.33333%;
  }

  .w3-col.m2 {
    width: 16.66666%;
  }

  .w3-col.m3,
  .w3-quarter {
    width: 24.99999%;
  }

  .w3-col.m4,
  .w3-third {
    width: 33.33333%;
  }

  .w3-col.m5 {
    width: 41.66666%;
  }

  .w3-col.m6,
  .w3-half {
    width: 49.99999%;
  }

  .w3-col.m7 {
    width: 58.33333%;
  }

  .w3-col.m8,
  .w3-twothird {
    width: 66.66666%;
  }

  .w3-col.m9,
  .w3-threequarter {
    width: 74.99999%;
  }

  .w3-col.m10 {
    width: 83.33333%;
  }

  .w3-col.m11 {
    width: 91.66666%;
  }

  .w3-col.m12 {
    width: 99.99999%;
  }
}

@media (min-width: 993px) {
  .w3-col.l1 {
    width: 8.33333%;
  }

  .w3-col.l2 {
    width: 16.66666%;
  }

  .w3-col.l3 {
    width: 24.99999%;
  }

  .w3-col.l4 {
    width: 33.33333%;
  }

  .w3-col.l5 {
    width: 41.66666%;
  }

  .w3-col.l6 {
    width: 49.99999%;
  }

  .w3-col.l7 {
    width: 58.33333%;
  }

  .w3-col.l8 {
    width: 66.66666%;
  }

  .w3-col.l9 {
    width: 74.99999%;
  }

  .w3-col.l10 {
    width: 83.33333%;
  }

  .w3-col.l11 {
    width: 91.66666%;
  }

  .w3-col.l12 {
    width: 99.99999%;
  }
}

.w3-rest {
  overflow: hidden;
}

.w3-stretch {
  margin-left: -16px;
  margin-right: -16px;
}

.w3-content,
.w3-auto {
  margin-left: auto;
  margin-right: auto;
}

.w3-content {
  max-width: 980px;
}

.w3-auto {
  max-width: 1140px;
}

.w3-cell-row {
  display: table;
  width: 100%;
}

.w3-cell {
  display: table-cell;
}

.w3-cell-top {
  vertical-align: top;
}

.w3-cell-middle {
  vertical-align: middle;
}

.w3-cell-bottom {
  vertical-align: bottom;
}

.w3-hide {
  display: none !important;
}

.w3-show-block,
.w3-show {
  display: block !important;
}

.w3-show-inline-block {
  display: inline-block !important;
}

@media (max-width: 1205px) {
  .w3-auto {
    max-width: 95%;
  }
}

@media (max-width: 600px) {
  .w3-modal-content {
    margin: 0 10px;
    width: auto !important;
  }

  .w3-modal {
    padding-top: 30px;
  }

  .w3-dropdown-hover.w3-mobile .w3-dropdown-content,
  .w3-dropdown-click.w3-mobile .w3-dropdown-content {
    position: relative;
  }

  .w3-hide-small {
    display: none !important;
  }

  .w3-mobile {
    display: block;
    width: 100% !important;
  }

  .w3-bar-item.w3-mobile,
  .w3-dropdown-hover.w3-mobile,
  .w3-dropdown-click.w3-mobile {
    text-align: center;
  }

  .w3-dropdown-hover.w3-mobile,
  .w3-dropdown-hover.w3-mobile .w3-btn,
  .w3-dropdown-hover.w3-mobile .w3-button,
  .w3-dropdown-click.w3-mobile,
  .w3-dropdown-click.w3-mobile .w3-btn,
  .w3-dropdown-click.w3-mobile .w3-button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .w3-modal-content {
    width: 500px;
  }

  .w3-modal {
    padding-top: 50px;
  }
}

@media (min-width: 993px) {
  .w3-modal-content {
    width: 900px;
  }

  .w3-hide-large {
    display: none !important;
  }

  .w3-sidebar.w3-collapse {
    display: block !important;
  }
}

@media (max-width: 992px) and (min-width: 601px) {
  .w3-hide-medium {
    display: none !important;
  }
}

@media (max-width: 992px) {
  .w3-sidebar.w3-collapse {
    display: none;
  }

  .w3-main {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .w3-auto {
    max-width: 100%;
  }
}

.w3-top,
.w3-bottom {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.w3-top {
  top: 0;
}

.w3-bottom {
  bottom: 0;
}

.w3-overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.w3-display-topleft {
  position: absolute;
  left: 0;
  top: 0;
}

.w3-display-topright {
  position: absolute;
  right: 0;
  top: 0;
}

.w3-display-bottomleft {
  position: absolute;
  left: 0;
  bottom: 0;
}

.w3-display-bottomright {
  position: absolute;
  right: 0;
  bottom: 0;
}

.w3-display-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.w3-display-left {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, -50%);
  -ms-transform: translate(-0%, -50%);
}

.w3-display-right {
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
}

.w3-display-topmiddle {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
}

.w3-display-bottommiddle {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
}

.w3-display-container:hover .w3-display-hover {
  display: block;
}

.w3-display-container:hover span.w3-display-hover {
  display: inline-block;
}

.w3-display-hover {
  display: none;
}

.w3-display-position {
  position: absolute;
}

.w3-circle {
  border-radius: 50%;
}

.w3-round-small {
  border-radius: 2px;
}

.w3-round,
.w3-round-medium {
  border-radius: 4px;
}

.w3-round-large {
  border-radius: 8px;
}

.w3-round-xlarge {
  border-radius: 16px;
}

.w3-round-xxlarge {
  border-radius: 32px;
}

.w3-row-padding,
.w3-row-padding>.w3-half,
.w3-row-padding>.w3-third,
.w3-row-padding>.w3-twothird,
.w3-row-padding>.w3-threequarter,
.w3-row-padding>.w3-quarter,
.w3-row-padding>.w3-col {
  padding: 0 8px;
}

.w3-container,
.w3-panel {
  padding: 0.01em 16px;
}

.w3-panel {
  margin-top: 16px;
  margin-bottom: 16px;
}

.w3-code,
.w3-codespan {
  font-family: Consolas, "courier new";
  font-size: 16px;
}

.w3-code {
  width: auto;
  background-color: #fff;
  padding: 8px 12px;
  border-left: 4px solid #1a3764;
  word-wrap: break-word;
}

.w3-codespan {
  color: crimson;
  background-color: #f1f1f1;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 110%;
}

.w3-card,
.w3-card-2 {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.w3-card-4,
.w3-hover-shadow:hover {
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 4px 20px 0 rgba(0, 0, 0, 0.19);
}

.w3-spin {
  animation: w3-spin 2s infinite linear;
}

@keyframes w3-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

.w3-animate-fading {
  animation: fading 10s infinite;
}

@keyframes fading {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.w3-animate-opacity {
  animation: opac 0.8s;
}

@keyframes opac {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.w3-animate-top {
  position: relative;
  animation: animatetop 0.4s;
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

.w3-animate-left {
  position: relative;
  animation: animateleft 0.4s;
}

@keyframes animateleft {
  from {
    left: -300px;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

.w3-animate-right {
  position: relative;
  animation: animateright 0.4s;
}

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

.w3-animate-bottom {
  position: relative;
  animation: animatebottom 0.4s;
}

@keyframes animatebottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

.w3-animate-zoom {
  animation: animatezoom 0.6s;
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.w3-animate-input {
  transition: width 0.4s ease-in-out;
}

.w3-animate-input:focus {
  width: 100% !important;
}

.w3-opacity,
.w3-hover-opacity:hover {
  opacity: 0.6;
}

.w3-opacity-off,
.w3-hover-opacity-off:hover {
  opacity: 1;
}

.w3-opacity-max {
  opacity: 0.25;
}

.w3-opacity-min {
  opacity: 0.75;
}

.w3-greyscale-max,
.w3-grayscale-max,
.w3-hover-greyscale:hover,
.w3-hover-grayscale:hover {
  filter: grayscale(100%);
}

.w3-greyscale,
.w3-grayscale {
  filter: grayscale(75%);
}

.w3-greyscale-min,
.w3-grayscale-min {
  filter: grayscale(50%);
}

.w3-sepia {
  filter: sepia(75%);
}

.w3-sepia-max,
.w3-hover-sepia:hover {
  filter: sepia(100%);
}

.w3-sepia-min {
  filter: sepia(50%);
}

.w3-tiny {
  font-size: 10px !important;
}

.w3-small {
  font-size: 12px !important;
}

.w3-medium {
  font-size: 15px !important;
}

.w3-large {
  font-size: 18px !important;
}

.w3-xlarge {
  font-size: 24px !important;
}

.w3-xxlarge {
  font-size: 36px !important;
}

.w3-xxxlarge {
  font-size: 48px !important;
}

.w3-jumbo {
  font-size: 64px !important;
}

.w3-left-align {
  text-align: left !important;
}

.w3-right-align {
  text-align: right !important;
}

.w3-justify {
  text-align: justify !important;
}

.w3-center {
  text-align: center !important;
}

.w3-border-0 {
  border: 0 !important;
}

.w3-border {
  border: 1px solid #ccc !important;
}

.w3-border-top {
  border-top: 1px solid #ccc !important;
}

.w3-border-bottom {
  border-bottom: 1px solid #ccc !important;
}

.w3-border-left {
  border-left: 1px solid #ccc !important;
}

.w3-border-right {
  border-right: 1px solid #ccc !important;
}

.w3-topbar {
  border-top: 6px solid #ccc !important;
}

.w3-bottombar {
  border-bottom: 6px solid #ccc !important;
}

.w3-leftbar {
  border-left: 6px solid #ccc !important;
}

.w3-rightbar {
  border-right: 6px solid #ccc !important;
}

.w3-section,
.w3-code {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.w3-margin {
  margin: 16px !important;
}

.w3-margin-top {
  margin-top: 16px !important;
}

.w3-margin-bottom {
  margin-bottom: 16px !important;
}

.w3-margin-left {
  margin-left: 16px !important;
}

.w3-margin-right {
  margin-right: 16px !important;
}

.w3-padding-small {
  padding: 4px 8px !important;
}

.w3-padding {
  padding: 8px 16px !important;
}

.w3-padding-large {
  padding: 12px 24px !important;
}

.w3-padding-16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.w3-padding-24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.w3-padding-32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.w3-padding-48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.w3-padding-64 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.w3-padding-top-64 {
  padding-top: 64px !important;
}

.w3-padding-top-48 {
  padding-top: 48px !important;
}

.w3-padding-top-32 {
  padding-top: 32px !important;
}

.w3-padding-top-24 {
  padding-top: 24px !important;
}

.w3-left {
  float: left !important;
}

.w3-right {
  float: right !important;
}

.w3-button:hover {
  color: #000 !important;
  background-color: #ccc !important;
}

.w3-transparent,
.w3-hover-none:hover {
  background-color: transparent !important;
}

.w3-hover-none:hover {
  box-shadow: none !important;
}

/* Colors */
.w3-amber,
.w3-hover-amber:hover {
  color: #000 !important;
  background-color: #ffc107 !important;
}

.w3-aqua,
.w3-hover-aqua:hover {
  color: #000 !important;
  background-color: #00ffff !important;
}

.w3-blue,
.w3-hover-blue:hover {
  color: #fff !important;
  background-color: #2196f3 !important;
}

.w3-light-blue,
.w3-hover-light-blue:hover {
  color: #000 !important;
  background-color: #87ceeb !important;
}

.w3-brown,
.w3-hover-brown:hover {
  color: #fff !important;
  background-color: #795548 !important;
}

.w3-cyan,
.w3-hover-cyan:hover {
  color: #000 !important;
  background-color: #00bcd4 !important;
}

.w3-blue-grey,
.w3-hover-blue-grey:hover,
.w3-blue-gray,
.w3-hover-blue-gray:hover {
  color: #fff !important;
  background-color: #607d8b !important;
}

.w3-green,
.w3-hover-green:hover {
  color: #fff !important;
  background-color: #1a3764 !important;
}

.w3-light-green,
.w3-hover-light-green:hover {
  color: #000 !important;
  background-color: #8bc34a !important;
}

.w3-indigo,
.w3-hover-indigo:hover {
  color: #fff !important;
  background-color: #3f51b5 !important;
}

.w3-khaki,
.w3-hover-khaki:hover {
  color: #000 !important;
  background-color: #f0e68c !important;
}

.w3-lime,
.w3-hover-lime:hover {
  color: #000 !important;
  background-color: #cddc39 !important;
}

.w3-orange,
.w3-hover-orange:hover {
  color: #000 !important;
  background-color: #ff9800 !important;
}

.w3-deep-orange,
.w3-hover-deep-orange:hover {
  color: #fff !important;
  background-color: #ff5722 !important;
}

.w3-pink,
.w3-hover-pink:hover {
  color: #fff !important;
  background-color: #e91e63 !important;
}

.w3-purple,
.w3-hover-purple:hover {
  color: #fff !important;
  background-color: #9c27b0 !important;
}

.w3-deep-purple,
.w3-hover-deep-purple:hover {
  color: #fff !important;
  background-color: #673ab7 !important;
}

.w3-red,
.w3-hover-red:hover {
  color: #fff !important;
  background-color: #f44336 !important;
}

.w3-sand,
.w3-hover-sand:hover {
  color: #000 !important;
  background-color: #fdf5e6 !important;
}

.w3-teal,
.w3-hover-teal:hover {
  color: #fff !important;
  background-color: #009688 !important;
}

.w3-yellow,
.w3-hover-yellow:hover {
  color: #000 !important;
  background-color: #ffeb3b !important;
}

.w3-white,
.w3-hover-white:hover {
  color: #000 !important;
  background-color: #fff !important;
}

.w3-black,
.w3-hover-black:hover {
  color: #fff !important;
  background-color: #000 !important;
}

.w3-grey,
.w3-hover-grey:hover,
.w3-gray,
.w3-hover-gray:hover {
  color: #000 !important;
  background-color: #9e9e9e !important;
}

.w3-light-grey,
.w3-hover-light-grey:hover,
.w3-light-gray,
.w3-hover-light-gray:hover {
  color: #000 !important;
  background-color: #f1f1f1 !important;
}

.w3-dark-grey,
.w3-hover-dark-grey:hover,
.w3-dark-gray,
.w3-hover-dark-gray:hover {
  color: #fff !important;
  background-color: #616161 !important;
}

.w3-pale-red,
.w3-hover-pale-red:hover {
  color: #000 !important;
  background-color: #ffdddd !important;
}

.w3-pale-green,
.w3-hover-pale-green:hover {
  color: #000 !important;
  background-color: #ddffdd !important;
}

.w3-pale-yellow,
.w3-hover-pale-yellow:hover {
  color: #000 !important;
  background-color: #ffffcc !important;
}

.w3-pale-blue,
.w3-hover-pale-blue:hover {
  color: #000 !important;
  background-color: #ddffff !important;
}

.w3-text-amber,
.w3-hover-text-amber:hover {
  color: #ffc107 !important;
}

.w3-text-aqua,
.w3-hover-text-aqua:hover {
  color: #00ffff !important;
}

.w3-text-blue,
.w3-hover-text-blue:hover {
  color: #2196f3 !important;
}

.w3-text-light-blue,
.w3-hover-text-light-blue:hover {
  color: #87ceeb !important;
}

.w3-text-brown,
.w3-hover-text-brown:hover {
  color: #795548 !important;
}

.w3-text-cyan,
.w3-hover-text-cyan:hover {
  color: #00bcd4 !important;
}

.w3-text-blue-grey,
.w3-hover-text-blue-grey:hover,
.w3-text-blue-gray,
.w3-hover-text-blue-gray:hover {
  color: #607d8b !important;
}

.w3-text-green,
.w3-hover-text-green:hover {
  color: #1a3764 !important;
}

.w3-text-light-green,
.w3-hover-text-light-green:hover {
  color: #8bc34a !important;
}

.w3-text-indigo,
.w3-hover-text-indigo:hover {
  color: #3f51b5 !important;
}

.w3-text-khaki,
.w3-hover-text-khaki:hover {
  color: #b4aa50 !important;
}

.w3-text-lime,
.w3-hover-text-lime:hover {
  color: #cddc39 !important;
}

.w3-text-orange,
.w3-hover-text-orange:hover {
  color: #ff9800 !important;
}

.w3-text-deep-orange,
.w3-hover-text-deep-orange:hover {
  color: #ff5722 !important;
}

.w3-text-pink,
.w3-hover-text-pink:hover {
  color: #e91e63 !important;
}

.w3-text-purple,
.w3-hover-text-purple:hover {
  color: #9c27b0 !important;
}

.w3-text-deep-purple,
.w3-hover-text-deep-purple:hover {
  color: #673ab7 !important;
}

.w3-text-red,
.w3-hover-text-red:hover {
  color: #f44336 !important;
}

.w3-text-sand,
.w3-hover-text-sand:hover {
  color: #fdf5e6 !important;
}

.w3-text-teal,
.w3-hover-text-teal:hover {
  color: #009688 !important;
}

.w3-text-yellow,
.w3-hover-text-yellow:hover {
  color: #d2be0e !important;
}

.w3-text-white,
.w3-hover-text-white:hover {
  color: #fff !important;
}

.w3-text-black,
.w3-hover-text-black:hover {
  color: #000 !important;
}

.w3-text-grey,
.w3-hover-text-grey:hover,
.w3-text-gray,
.w3-hover-text-gray:hover {
  color: #757575 !important;
}

.w3-text-light-grey,
.w3-hover-text-light-grey:hover,
.w3-text-light-gray,
.w3-hover-text-light-gray:hover {
  color: #f1f1f1 !important;
}

.w3-text-dark-grey,
.w3-hover-text-dark-grey:hover,
.w3-text-dark-gray,
.w3-hover-text-dark-gray:hover {
  color: #3a3a3a !important;
}

.w3-border-amber,
.w3-hover-border-amber:hover {
  border-color: #ffc107 !important;
}

.w3-border-aqua,
.w3-hover-border-aqua:hover {
  border-color: #00ffff !important;
}

.w3-border-blue,
.w3-hover-border-blue:hover {
  border-color: #2196f3 !important;
}

.w3-border-light-blue,
.w3-hover-border-light-blue:hover {
  border-color: #87ceeb !important;
}

.w3-border-brown,
.w3-hover-border-brown:hover {
  border-color: #795548 !important;
}

.w3-border-cyan,
.w3-hover-border-cyan:hover {
  border-color: #00bcd4 !important;
}

.w3-border-blue-grey,
.w3-hover-border-blue-grey:hover,
.w3-border-blue-gray,
.w3-hover-border-blue-gray:hover {
  border-color: #607d8b !important;
}

.w3-border-green,
.w3-hover-border-green:hover {
  border-color: #1a3764 !important;
}

.w3-border-light-green,
.w3-hover-border-light-green:hover {
  border-color: #8bc34a !important;
}

.w3-border-indigo,
.w3-hover-border-indigo:hover {
  border-color: #3f51b5 !important;
}

.w3-border-khaki,
.w3-hover-border-khaki:hover {
  border-color: #f0e68c !important;
}

.w3-border-lime,
.w3-hover-border-lime:hover {
  border-color: #cddc39 !important;
}

.w3-border-orange,
.w3-hover-border-orange:hover {
  border-color: #ff9800 !important;
}

.w3-border-deep-orange,
.w3-hover-border-deep-orange:hover {
  border-color: #ff5722 !important;
}

.w3-border-pink,
.w3-hover-border-pink:hover {
  border-color: #e91e63 !important;
}

.w3-border-purple,
.w3-hover-border-purple:hover {
  border-color: #9c27b0 !important;
}

.w3-border-deep-purple,
.w3-hover-border-deep-purple:hover {
  border-color: #673ab7 !important;
}

.w3-border-red,
.w3-hover-border-red:hover {
  border-color: #f44336 !important;
}

.w3-border-sand,
.w3-hover-border-sand:hover {
  border-color: #fdf5e6 !important;
}

.w3-border-teal,
.w3-hover-border-teal:hover {
  border-color: #009688 !important;
}

.w3-border-yellow,
.w3-hover-border-yellow:hover {
  border-color: #ffeb3b !important;
}

.w3-border-white,
.w3-hover-border-white:hover {
  border-color: #fff !important;
}

.w3-border-black,
.w3-hover-border-black:hover {
  border-color: #000 !important;
}

.w3-border-grey,
.w3-hover-border-grey:hover,
.w3-border-gray,
.w3-hover-border-gray:hover {
  border-color: #9e9e9e !important;
}

.w3-border-light-grey,
.w3-hover-border-light-grey:hover,
.w3-border-light-gray,
.w3-hover-border-light-gray:hover {
  border-color: #f1f1f1 !important;
}

.w3-border-dark-grey,
.w3-hover-border-dark-grey:hover,
.w3-border-dark-gray,
.w3-hover-border-dark-gray:hover {
  border-color: #616161 !important;
}

.w3-border-pale-red,
.w3-hover-border-pale-red:hover {
  border-color: #ffe7e7 !important;
}

.w3-border-pale-green,
.w3-hover-border-pale-green:hover {
  border-color: #e7ffe7 !important;
}

.w3-border-pale-yellow,
.w3-hover-border-pale-yellow:hover {
  border-color: #ffffcc !important;
}

.w3-border-pale-blue,
.w3-hover-border-pale-blue:hover {
  border-color: #e7ffff !important;
}

/*# sourceMappingURL=bootstrap.css.map */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

.fade-in {
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  -moz-animation: fadeIn 2s;
  -o-animation: fadeIn 2s;
  -ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    margin-top: 50px;
  }

  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
    margin-top: 50px;
  }

  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    margin-top: 50px;
  }

  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
    margin-top: 50px;
  }

  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
    margin-top: 50px;
  }

  100% {
    opacity: 1;
    margin-top: 0px;
  }
}

.dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdowns {
  position: relative;
  display: inline-block;
  align-items: flex-end;
}

.dropdowns-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
}

.dropdowns-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdowns-content a:active {
  background-color: #ddd;
}

.dropdowns:hover .dropdowns-content {
  display: block;
}

.dropdowns:hover .dropbtn {
  background-color: #3e8e41;
}

.sidebar-menu {
  width: 300px;
  height: 100%;
  position: absolute;
  top: 0;
  left: -300px;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
  background: #333;
}

.sidebar-menu .open {
  -webkit-transform: translateX(300px);
  transform: translateX(300px);
}

.sidebar-menu .open .menu li a {
  padding: 15px 20px;
  color: #fff;
}

.text-2 {
  font-size: 2px;
}

.text-4 {
  font-size: 4px;
}

.text-6 {
  font-size: 6px;
}

.text-8 {
  font-size: 8px;
}

.text-10 {
  font-size: 10px;
}

.text-11 {
  font-size: 11px;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px;
}

.text-16 {
  font-size: 16px;
}

.text-18 {
  font-size: 18px;
}

.text-20 {
  font-size: 20px;
}

.text-22 {
  font-size: 22px;
}

.text-24 {
  font-size: 24px;
}

.text-26 {
  font-size: 26px;
}

.text-28 {
  font-size: 28px;
}

.text-30 {
  font-size: 30px;
}

.text-32 {
  font-size: 32px;
}

.design-table {
  margin-bottom: 1rem;
  background-color: transparent;
}

.design-table thead {
  /* height: 72px; */
  font-style: normal;
}

.design-table thead tr th {
  padding-left: 12px;
  padding-right: 12px;
}

.design-table tbody tr:first-child td:first-child {
  border-top-left-radius: 16px;
}

.design-table tbody tr:first-child td:last-child {
  border-top-right-radius: 16px;
}

.design-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 16px;
}

.design-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 16px;
}

.design-table tbody {
  border-radius: 16px;
  background-color: #ffffff;
}

.design-table tbody tr {
  /* height: 72px; */
  border-bottom: 1px solid #f0f0f0;
}

.design-table tbody tr td {
  padding-left: 12px;
  padding-right: 12px;
}

.design-table-sell {
  margin-bottom: 1rem;
  background-color: transparent;
}

.design-table-sell thead {
  /* height: 72px; */
  font-style: normal;
}

.design-table-sell thead tr th {
  padding-left: 12px;
  padding-right: 12px;
  background-color: #cdcdcd;
}

.design-table-sell-primary thead tr th {
  padding-left: 12px;
  padding-right: 12px;
  background-color: #232f85;
  color: #ffffff;
}

.design-table-sell thead tr:first-child th:first-child {
  border-top-left-radius: 16px;
}

.design-table-sell thead tr:first-child th:last-child {
  border-top-right-radius: 16px;
}

.design-table-sell thead tr:last-child th:first-child {
  border-bottom-left-radius: 16px;
}

.design-table-sell thead tr:last-child th:last-child {
  border-bottom-right-radius: 16px;
}

.design-table-sell thead {
  border-radius: 16px;
  background-color: #ffffff;
}

.design-table tbody tr {
  /* height: 72px; */
  border-bottom: 1px solid #f0f0f0;
}

.design-table tbody tr td {
  padding-left: 12px;
  padding-right: 12px;
}

.status-0 {
  background-color: #2f977129;
  padding: 2px 8px;
  border-radius: 8px;
  color: #2f9771;
}

.status-1 {
  background-color: #2f559729;
  padding: 2px 8px;
  border-radius: 8px;
  color: #2f5597;
}

.status-2 {
  background-color: #63636329;
  padding: 2px 8px;
  border-radius: 8px;
  color: #636363;
}

.status-3 {
  background-color: #da0e0e29;
  padding: 2px 8px;
  border-radius: 8px;
  color: #da0e0e;
}

.btn-circle {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border: 0px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-circle-primary {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #2f5597;
  color: #ffffff;
  border: 0px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-circle-white {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #ffffff;
  color: #b5b5c3;
  border: 0px;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-150px {
  width: 150px;
  min-width: 150px;
}

.show-modal {
  display: block;
  position: fixed;
  z-index: 1040;
  background-color: #00000050;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  opacity: 1;
  animation-name: fade-in;
  animation-duration: 0.2s;
}

.hide-modal {
  display: none;
  opacity: 0;
  animation-name: fade-out;
  animation-duration: 0.2s;
}

.blur {
  backdrop-filter: blur(5px);
}

.box-modal {
  position: absolute;
  right: 0px;
  width: 514px;
  max-width: 100%;
  height: 100vh;
  overflow-y: scroll;
  overflow: scroll;
  background-color: #ffffff;
  animation-name: slide-in;
  animation-duration: 0.2s;
}

.hide-box-modal {
  position: absolute;
  right: -514px;
  width: 514px;
  height: 100vh;
  overflow-y: scroll;
  background-color: #ffffff;
  animation-name: slide-out;
  animation-duration: 0.2s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes slide-in {
  from {
    right: -514px;
  }

  to {
    right: 0px;
  }
}

@keyframes slide-out {
  from {
    right: 0px;
  }

  to {
    right: -514px;
  }
}

.table thead th {
  vertical-align: center;
  border-bottom: 0px;
}

.table th,
.table td {
  /* padding: 0.75rem; */
  vertical-align: center;
  border-top: 0px;
}

.user-image {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: gray;
}

.user-image-big {
  width: 90px;
  height: 90px;
  border-radius: 12px;
  background-color: gray;
}

.react-slideshow-wrapper.slide {
  border-radius: 16px;
}

.tag-name {
  height: 84px;
  border-radius: 16px;
}

.each-slide {
  height: 254px;
  border-radius: 16px;
}

.select_checked {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: 1px solid #2f5597;
  padding: 1px;
}

.select_checked_in {
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: #ffffff;
}

.select_checked_out {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  border: 1px solid #ffffff;
}

.swal2-popup {
  border-radius: 16px !important;
}

.swal2-confirm {
  background-color: #2f5597 !important;
}

.pagination li {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border: 0px;
  padding-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 4px;
}

.pagination .active {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #2f5597;
  border: 0px;
  padding-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .active a {
  color: #ffffff;
}

.pagination .disabled a {
  color: #b5b5c3;
}

.pagination li:first-child {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #ffffff;
  color: #b5b5c3;
  border: 0px;
  padding-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 4px;
}

.pagination li:last-child {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #ffffff;
  color: #b5b5c3;
  border: 0px;
  padding-top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 4px;
}

.loading {
  background: #00000030;
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swal2-styled {
  border-radius: 10px !important;
}

.modal-filter {
  position: absolute;
  width: 374px;
  z-index: 2000;
}

.bg-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000040;
  z-index: 2000;
}

.bg-primary-cash {
  background-color: #0f3196 !important;
}

.expanded {
  animation-name: expanded-in;
  animation-duration: 1s;
}

@keyframes expanded-in {
  from {
    height: 0px;
    line-height: 0;
    overflow: hidden;
    position: absolute;
  }

  to {
    height: 72px;
    line-height: 1.5;
    overflow: hidden;
    position: relative;
  }
}

.pointer {
  cursor: pointer;
}

.form-control {
  height: 44px;
  border: 0px;
  background-color: #f5f5f5;
}

.form-control-select {
  display: block;
  width: 100%;
  height: 44px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.bg-secondary-light {
  background-color: #f5f5f5 !important;
}

.btn {
  height: 44px;
}

.btn-primary {
  background: linear-gradient(90deg, #0f3196 0%, #067cc0 100%);
}

.bg-cash-primary {
  background: linear-gradient(0deg, #0f3196 0%, #067cc0 100%);
}

.btn-white {
  background-color: #ffffff !important;
  color: #333333;
}

.wpx-16 {
  width: 16px;
  min-width: 16px;
  max-width: 16px;
}

.wpx-20 {
  width: 20px;
  min-width: 20px;
  max-width: 20px;
}

.wpx-22 {
  width: 22px;
  min-width: 22px;
  max-width: 22px;
}

.wpx-24 {
  width: 24px;
  min-width: 24px;
  max-width: 24px;
}

.wpx-26 {
  width: 26px;
  min-width: 26px;
  max-width: 26px;
}

.wpx-32 {
  width: 32px;
  min-width: 32px;
  max-width: 32px;
}

.wpx-38 {
  width: 38px;
  min-width: 38px;
  max-width: 38px;
}

.wpx-40 {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
}

.wpx-50 {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
}

.wpx-70 {
  width: 70px;
  min-width: 70px;
  max-width: 70px;
}

.wpx-80 {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
}

.wpx-90 {
  width: 90px;
  min-width: 90px;
  max-width: 90px;
}

.wpx-100 {
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}

.wpx-120 {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.wpx-150 {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}

.wpx-160 {
  width: 160px;
  min-width: 160px;
  max-width: 160px;
}

.wpx-170 {
  width: 170px;
  min-width: 170px;
  max-width: 170px;
}

.wpx-180 {
  width: 180px;
  min-width: 180px;
  max-width: 180px;
}

.wpx-200 {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}

.wpx-234 {
  width: 234px;
  min-width: 234px;
  max-width: 234px;
}

.wpx-300 {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
}

.hpx-16 {
  height: 16px;
  min-height: 16px;
  max-height: 16px;
}

.hpx-20 {
  height: 20px;
  min-height: 20px;
  max-height: 20px;
}

.hpx-22 {
  height: 22px;
  min-height: 22px;
  max-height: 22px;
}

.hpx-24 {
  height: 24px;
  min-height: 24px;
  max-height: 24px;
}

.hpx-26 {
  height: 26px;
  min-height: 26px;
  max-height: 26px;
}


.hpx-32 {
  height: 32px;
  min-height: 32px;
  max-height: 32px;
}

.hpx-80 {
  height: 80px;
  min-height: 80px;
  max-height: 80px;
}

.swal2-confirm {
  background-color: #fff !important;
  color: #232f85 !important;
  border: 2px solid #232f85 !important;
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.bg-secondary-light {
  background-color: #f5f5f5;
}

select {
  -webkit-appearance: none;
}

.bg-white {
  background-color: #ffffff;
}

.border-dash {
  border: 2px dashed #b3b3b3;
}

.product-img {
  height: 100px;
  width: 100%;
  max-width: 100%;
  background-color: #ffffff;
  position: relative;
  object-fit: contain;
}

.user-no-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-empty-img {
  height: 100px;
  width: 100%;
  max-width: 100%;
  background-color: #ffffff;
  position: relative;
  object-fit: contain;
}

.text-empty {
  color: #bbbbbb !important;
}

.sold-out {
  height: 100px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.run-number {
  width: 26px;
  height: 26px;
  border-radius: 13px;
  background-color: #232f85;
  color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  top: 0;
}

.maxh-60 {
  max-height: 60vh;
}

.maxh-70 {
  max-height: 70vh;
}

.maxh-75 {
  max-height: 75vh;
}

.overflow-scroll {
  overflow: scroll;
}

.none-product {
  height: 120px;
  width: 178px;
  object-fit: cover;
}

.btn-green {
  background-color: #2aca9a;
  color: #fff;
}

.btn-green:hover {
  background-color: #2aca9a90;
}

.table-striped tbody tr {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

.border-primary {
  border: 2px solid #232f85;
}

.rounded-8 {
  border-radius: 8px !important;
}

.bg-blue-sky {
  background-color: #e3f8ff;
}

.clock-icon {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

input[type="checkbox"],
input[type="radio"] {
  accent-color: #bd0392;
}

.w-20 {
  width: 20%;
}

.w-33 {
  width: 33.33%;
}

.w-20-btn {
  width: 20%;
}

@media (max-width: 575.98px) {
  .w-20-btn {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .w-20-btn {
    width: 33.33%;
  }
}

@media (min-width: 768px) {
  .w-20-btn {
    width: 33.33%;
  }
}

@media (min-width: 992px) {
  .w-20-btn {
    width: 20%;
  }
}

@media (min-width: 1200px) {
  .w-20-btn {
    width: 20%;
  }
}

.btn-calculate {
  height: 75px !important;
}

.btn-cal-secondary {
  width: 100%;
  height: 62px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  font-size: 20px;
}

.btn-cal-primary {
  width: 100%;
  height: 62px;
  background-color: #232f85;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  font-size: 20px;
}

.p-01 {
  padding: 2px;
}

.logo-type {
  width: 64px;
  height: 64px;
  object-fit: contain;
}

.css-1s2u09g-control {
  width: 100%;
  height: 44px;
  border: 0px !important;
  background-color: #00000000 !important;
  margin-top: -4px;
}

.css-1pahdxg-control {
  width: 100%;
  height: 44px;
  border: 0px !important;
  background-color: #00000000 !important;
  margin-top: -4px;
  border-color: #00000000 !important;
  border-radius: 10px !important;
}

.css-1okebmr-indicatorSeparator {
  width: 0px !important;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f5f5f5;
}

.img {
  opacity: 1;
}

.img:hover {
  opacity: 0.75;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner-container {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000030;
  z-index: 10000;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.status {
  padding: 0px 12px;
  border-radius: 6px;
  font-size: 14px;
  width: 100px;
  height: 21px;
}

.status-green {
  background-color: #5fb866;
  color: #ffffff;
}

.status-black {
  background-color: #333333;
  color: #ffffff;
}

.status-yellow {
  background-color: #ffc232;
  color: #333333;
}

.status-blue {
  background-color: #232f85;
  color: #ffffff;
}

.status-gray {
  background-color: #c9c9c9;
  color: #ffffff;
}

.status-red {
  background-color: #eb5757;
  color: #ffffff;
}

.general-cash {
  border-radius: 50px;
  padding: 8px 24px;
  color: #ffffff;
}

.status-grey-type {
  background-color: #c9c9c9;
  border-radius: 6px;
  padding: 2px 8px;
  color: #ffffff;
  font-size: 1rem;
}

.border-secondary {
  border: 1px #e6e6e6 solid !important;
}

.bg-white {
  background-color: #ffffff;
}

.overflow-product {
  height: 100%;
  max-height: 100vh;
  z-index: 0;
  overflow: scroll;
}

.point-date-all {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #232f85;
}

.point-date-yellow {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #FAB440;
}

.point-date-danger {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #eb5757;
}

.point-date-success {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #5fb866;
}

.bg-green {
  background: #5fb866;
}

.bg-danger {
  background: #eb5757;
}

.bg-yellow {
  background: #FAB440
}

.bg-bule {
  background: #232f85;
}

.bg-gray-f2 {
  background: #F2F2F2;
}

.text-bule {
  color: #232f85;
}

.text-yellow {
  color: #FAB440
}

.text-danger {
  color: #eb5757;
}

.text-green {
  color: #5fb866;
}

.text-gray-f2 {
  background: #F2F2F2;
}


.bottom-menu {
  left: 0;
  right: 0;
}

.check-box {
  /* padding: 0px 12px; */
  border-radius: 50%;
  width: 18px;
  height: 18px;
}

@media (min-width: 993px) {
  .bottom-menu {
    left: 260px;
    right: 0;
  }
}

.breadcrumb-component {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  display: flex;
  padding: 0px 32px;
  border-left: 1px solid #c8ced3;
  left: 0;
  right: 0;
  z-index: 1039;
}

.task {
  width: 100%;
}

.text-bold {
  font-weight: bold;
}

/* @media (min-width: 993px) {
  .breadcrumb-component {
    height: 60px;
  }
} */
.table-responsive {
  min-height: 400px;
}

.szh-menu {
  left: unset !important;
  right: 0px;
}

.cal {
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.cal>div {
  height: 60px;
  background-color: #f4f3f3;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 10px;
}

.cal:hover>div {
  background-color: #e5e5e5;
}

.cal>.primary {
  background-color: #232f85;
  color: var(--white);
}

.cal:hover>.primary {
  background-color: #e5e5e5;
  color: var(--white);
}

.text-msg {
  color: #BABABA !important
}

.border-top-1 {
  border-top: 1px solid #e5e5e5;
}