.container_sold_out {
  position: relative;
}

.center_sold_out {
  position: absolute;
  top: 5%;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.text_opacity {
  opacity: 0.5;
}

.text-32 {
  font-size: 32px !important;
}